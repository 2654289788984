import { Form } from "react-bootstrap";
import { ObjectUtils } from "../../utils";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FileComponent = (props: any) => {
  const { inputdata } = props;
  const onSelectedFileChangeHandler = (event: any, index: number) => {
    if (inputdata && inputdata[index].handlechange) {
      event.preventDefault();
      return inputdata[index].handlechange(event);
    }
  };
  console.log("item: ", inputdata);
  return inputdata?.map((item: any, index: number) => {
    return (
      !item?.skip && (
        <>
          <Form.Group className={item.fgclass || "mb-0"} controlId={`${ObjectUtils.getControlId(item.texttoread)}${index}`}>
            {item?.display && <Form.Label className={item?.lblclassname}>{item?.display}</Form.Label>}
            {item?.mode == "view" && <Form.Label className={item?.lblclassname}>{item?.value}</Form.Label>}
            {item?.mode == "edit" && (
              <>
                <div className={item?.gridrow ?? ""}>
                  <input
                    id={ObjectUtils?.getControlId(item?.display)}
                    style={{ display: "none" }}
                    type="file"
                    className={item?.ctlclass || `invisible position-absolute1`}
                    name={item?.name || ObjectUtils?.getControlId(item?.texttoread)}
                    accept={item?.filterextentions?.join()}
                    onChange={(event) => onSelectedFileChangeHandler(event, index)}
                  />
                  {item?.browsefilelabel && (
                    <label htmlFor={ObjectUtils?.getControlId(item?.display)} className="mt-1">
                      <FontAwesomeIcon style={{ marginRight: "10px" }} className="icon-action" icon={faFile} />
                      <span
                        style={{ cursor: "pointer" }}
                        tabIndex={0}
                        role="button"
                        aria-controls="cupload"
                        // onKeyDown={(event) => onSelectedFileThroughTab(event)}
                        // onClick={(event) => onSelectedFileThroughTab(event)}
                        aria-label={item?.browsefilelabel}
                      >
                        {item?.browsefilelabel}
                      </span>
                    </label>
                  )}
                </div>

                {item?.errormessage && (
                  <div className="clearfix">
                    <span className={`${item?.propmessageclass}`} role="alert">
                      {item?.errormessage}
                    </span>
                  </div>
                )}
                {item?.error && (
                  <Form.Control.Feedback type="invalid" role="alert" className={`ml-error`}>
                    {item?.error}
                  </Form.Control.Feedback>
                )}
              </>
            )}
          </Form.Group>
        </>
      )
    );
  });
};

export default FileComponent;
