import { TableRowComponent } from "../Form";
import { AlertComponent, ButtonComponent } from "../../components";
import { Fragment } from "react";

export const TableComponent = (props: any) => {
  const { className, header, body, footer } = props.tableObject || {};
  return (
    <Fragment key={props.uniqueKey || header?.headerData}>
      <table className={className || ""}>
        {header?.headerData && (
          <thead className={header?.headerClass || ""}>
            <tr className={header?.headerRowClass || ""}>
              {header?.headerData?.map((head: any) => {
                return (
                  head && (
                    <Fragment key={head?.headername}>
                      {head?.headername && (
                        <th className={head?.headerColumnClass}>
                          <span dangerouslySetInnerHTML={{__html: head?.headername}}/>
                          {head?.isWellnessValue && <sup className="ml-errorColorOnly sup-heading">1</sup>}
                        </th>
                      )}
                    </Fragment>
                  )
                );
              })}
            </tr>
          </thead>
        )}
        <tbody className={body?.bodyClass || ""}>
          {body ? (
            body?.gridRowData?.map((gridRow: any, rowIndex: number) => {
              return gridRow ? (
                <tr key={rowIndex} className={body?.bodyRowClass || ""}>
                  <TableRowComponent benefitName={props?.benefitName} rowClass={body?.bodyRowClass} gridRowData={gridRow} />
                </tr>
              ) : null;
            })
          ) : (
            <tr className="borderbottom">
              <td colSpan={header?.headerData?.length}>&nbsp;</td>
            </tr>
          )}
        </tbody>
        {footer && (
          <tfoot className={footer?.footClass || ""}>
            {footer?.footLabel && <div dangerouslySetInnerHTML={{ __html: footer?.footLabel }} />}
            {footer?.btnInput && <ButtonComponent btndata={footer?.btnInput} />}
            {footer &&
              footer?.gridFooterData?.map((gridRow: any, rowIndex: number) => {
                return gridRow ? (
                  <tr key={rowIndex} className={body?.bodyRowClass || ""}>
                    <TableRowComponent benefitName={props?.benefitName} rowClass={body?.bodyRowClass} gridRowData={gridRow} />
                  </tr>
                ) : null;
                /* return (
                  <Fragment key={rowIndex}>
                    {gridRow ? <TableRowComponent rowClass={body?.bodyRowClass} gridRowData={gridRow} /> : null}
                  </Fragment>
                ); */
              })}
          </tfoot>
        )}
      </table>
      {footer?.messageText && <AlertComponent marginleft={false} propmessageType={footer?.messageType} propmessage={footer?.messageText} />}
    </Fragment>
  );
};

export default TableComponent;
