import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { RootState } from "../../reducers";
import { ButtonComponent, ModalComponent } from "../../components";
import { ObjectUtils } from "../../utils";
import { AlertComponent } from "../Alert";
import { DeleteIconComponent, EditIconComponent, TooltipIconComponent } from "../Icon";

export const TextFieldComponent = (props: any) => {
  const contentFooter = useSelector((state: RootState) => state?.common?.contentFooter?.contentResource?.contents);
  const closeButtonText = ObjectUtils.extractLabelFromObject(contentFooter || {}, "btnClose");
  const lblCopyPasteMessage = ObjectUtils.extractLabelFromObject(contentFooter || {}, "lblCopyPasteMessage");
  const [showModal, setModal] = useState<boolean>(false);
  const { inputdata } = props;
  const onChange = (event: any, index: number, key = 0, isnumeric = false) => {
    event.preventDefault();
    const { name, value }: any = event.target;

    if (isnumeric && /\D/.test(value)) {
      return false;
    }

    const keyHandler = inputdata?.[index];
    if (keyHandler?.textGroup?.[key]?.handlechange) {
      return keyHandler.textGroup?.[key].handlechange({ [name]: value });
    } else if (keyHandler?.handlechange) {
      return keyHandler.handlechange({ [name]: value });
    }
  };

  const onKeyDown = (event: any, index: number, key: number, isCalander = false) => {
    event.persist();
    const keyHandler = inputdata?.[index];
    const isEnter = event?.key === "Enter" || event?.keyCode === 13;
    /* if (key !== 0 && keyHandler.textGroup?.[key].handlechange) {
      return keyHandler.textGroup?.[key].handlechange(event);
    } else  */

    if (key !== 0 && keyHandler.handlekeydown && isEnter) {
      return keyHandler.handlekeydown(event);
    }
    if (isCalander) {
      return keyHandler.handlekeydown(event, index);
    }
  };

  const handleClose = () => {
    setModal(!showModal);
  };

  const modalObject = {
    showModal: showModal,
    clickHandler: handleClose,
    dialogClass: "infopopup",
    showHeaderCloseBtn: false,
    head: {
      modalTitle: lblCopyPasteMessage.display
    },
    body: {
      bodyClass: "p-0"
    },
    css: {
      modalHeader: "bg-white border-0 d-flex justify-content-center modal-header pt-4",
      modalTitle: "text-black",
      modalFooter: "border-0"
    },
    footer: {
      footerData: [
        {
          variant: "secondary",
          display: closeButtonText.display,
          onSubmit: handleClose,
          className: "mx-auto"
        }
      ]
    }
  };

  const preventCopyPaste = (event: any, index: number) => {
    const keyHandler = inputdata?.[index];

    if (keyHandler?.disableCutCopyPaste) {
      event.preventDefault();
      // alert("Copying and pasting is not allowed!");
      setModal(!showModal);
    }
  };

  return inputdata?.map((item: any, index: number) => {
    const uniqId = item?.name?.replace(/\s/g, "") + "-" + index;

    return (
      !item?.skip && (
        <Fragment key={uniqId}>
          <Form.Group className={item?.fgclass}>
            {item?.display && (
              <Form.Label
                htmlFor={item?.name}
                // className={item?.lblclassname}
                bsPrefix={item?.lblbpfixclassname ? item?.lblbpfixclassname : item?.lblclassname}
                dangerouslySetInnerHTML={{ __html: item?.display + " " }}
              />
            )}
            {item?.tooltip && item?.tooltip?.type === "tooltip" && <ButtonComponent btndata={[item?.tooltip]} />}
            {item?.tooltip && item?.tooltip?.type === "tooltipicon" && <TooltipIconComponent inputData={item?.tooltip} />}
            {/* {item?.tooltip && <ButtonComponent btndata={[item?.tooltip]}></ButtonComponent>} */}
            {item?.mode == "view" && item?.value && <Form.Label className={item?.lblclassname} dangerouslySetInnerHTML={{ __html: item?.value }} />}{" "}
            {item?.datetime && <time dateTime={item?.datetime}>{item?.datetime}</time>}
            {item?.mode == "edit" &&
              (item?.textGroup?.length ? (
                <>
                  <div className={item?.tgclassName || "address-input"}>
                    {item?.textGroup?.map((item_name: any, key: number) => (
                      <Fragment key={`${item_name?.name}${key}`}>
                        {(item_name?.type === "icon1" || item_name?.type === "delete1" || item_name?.type === "tooltip") && <ButtonComponent btndata={[item_name]} />}
                        {item_name?.type === "select" && (
                          <div className="name float-right">
                            <Form.Label className={`edit-input-label`} />
                            <Form.Select
                              onChange={(event) => onChange(event, index, key)}
                              name={item_name?.name || ObjectUtils.getControlId(item_name?.texttoread)}
                              className={item_name?.classname}
                              // bsPrefix={item_name?.classname ? item_name?.classname : " "}
                              defaultValue={item_name?.selectedvalue}
                            >
                              {item_name?.options?.map((option: any, i: number) => (
                                <option key={option?.value} value={item_name?.options[i].value}>
                                  {item_name?.showvalue ? option?.value : option?.text}
                                </option>
                              ))}
                            </Form.Select>
                          </div>
                        )}
                        {item_name?.type === "text" && (
                          <div className={`${item_name?.profileWrapper ?? ""}`}>
                            <Form.Control
                              id={item?.controlid || item?.name}
                              /* min="0"
                              max="100" */
                              readOnly={item?.readonly}
                              type={item_name?.type}
                              placeholder={item_name?.texttoread}
                              value={item_name?.value}
                              name={item_name?.name || ObjectUtils.getControlId(item_name?.texttoread)}
                              aria-label={item?.arialabel}
                              className={item_name?.classname ?? "login-input-field"}
                              onChange={(event) => onChange(event, index, key)}
                              onKeyDown={(event) => onKeyDown(event, index, key, item_name?.isCalander)}
                              minLength={item_name?.minLength}
                              maxLength={item_name?.maxLength}
                              ref={item_name.refInput}
                              disabled={item_name?.disabled}
                              required={item?.required || item?.error}
                            />

                            {item_name?.errormessage && <AlertComponent marginleft={false} propmessageType="error" propmessage={item_name?.errormessage} />}
                            {item_name?.error && (
                              <Form.Control.Feedback type="invalid" role="alert" className={`ml-error`}>
                                {item_name?.error}
                              </Form.Control.Feedback>
                            )}
                          </div>
                        )}
                        {item_name?.type === "number" && (
                          <>
                            <Form.Control
                              id={item?.controlid || item?.name}
                              /* min="0"
                              max="100" */
                              readOnly={item?.readonly}
                              type={item_name?.type}
                              placeholder={item_name?.texttoread}
                              value={item_name?.value}
                              name={item_name?.name || ObjectUtils.getControlId(item_name?.texttoread)}
                              aria-label={item?.arialabel}
                              className={item_name?.classname ?? "login-input-field"}
                              onChange={(event) => onChange(event, index, key)}
                              onKeyDown={(event) => onKeyDown(event, index, key, item_name?.isCalander)}
                              minLength={item_name?.minLength}
                              maxLength={item_name?.maxLength}
                              ref={item_name.refInput}
                              disabled={item_name?.disabled}
                              required={item?.required || item?.error}
                            />
                            {item_name?.errormessage && <AlertComponent marginleft={false} propmessageType="error" propmessage={item_name?.errormessage} />}
                            {item_name?.error && (
                              <Form.Control.Feedback type="invalid" role="alert" className={`ml-error`}>
                                {item_name?.error}
                              </Form.Control.Feedback>
                            )}
                          </>
                        )}
                      </Fragment>
                    ))}
                  </div>
                  <div className="btnsaction d-flex justify-content-between">
                    {item?.textGroup?.map((item_name: any, key: number) => (
                      <Fragment key={`${item_name?.name}${key}`}>
                        {item_name?.type === "label" && <span className="d-flex lbl-percentage">%</span>}
                        {item_name?.type === "editicon" && <EditIconComponent inputData={item_name} />}
                        {item_name?.type === "deleteicon" && <DeleteIconComponent inputData={item_name} />}
                        {item_name?.type === "tooltipicon" && <TooltipIconComponent inputData={item_name} />}
                      </Fragment>
                    ))}
                  </div>
                </>
              ) : (
                <div className={item?.gridrow}>
                  <Form.Control
                    readOnly={item?.readonly}
                    id={item?.controlid || item?.name}
                    type={item?.type}
                    placeholder={item?.texttoread}
                    aria-label={item?.arialabel}
                    value={item?.value}
                    // pattern={item?.regex ? "/^-?d+.?d*$/" : ""}
                    name={item?.name || ObjectUtils.getControlId(item?.texttoread)}
                    // className={item?.classname || "login-input-field"}
                    onChange={(event) => onChange(event, index, 0, item?.regex)}
                    onKeyDown={(event) => onKeyDown(event, index, 0, item?.isCalander)}
                    onCopy={(event) => preventCopyPaste(event, index)}
                    onPaste={(event) => preventCopyPaste(event, index)}
                    onCut={(event) => preventCopyPaste(event, index)}
                    minLength={item?.minLength}
                    maxLength={item?.maxLength}
                    ref={item.refInput}
                    disabled={item?.disabled}
                    required={item?.required || item?.error}
                    bsPrefix={item?.classname ? (item?.readonly ? item?.classname : "form-control " + item?.classname) : "form-control login-input-field"}
                    isInvalid={item.isInvalid}
                  />

                  {item?.errormessage && <AlertComponent marginleft={false} propmessageType="error" propmessage={item?.errormessage} />}
                  {item?.error && (
                    <Form.Control.Feedback type="invalid" role="alert" className={`ml-error error-margin`}>
                      {item?.error}
                    </Form.Control.Feedback>
                  )}
                </div>
              ))}
          </Form.Group>
          {item?.seperator && <span className={`input-sep align-items-center ${item?.isFocused ? "show" : "hide"}`} dangerouslySetInnerHTML={{ __html: item?.seperator }} />}
          {showModal && <ModalComponent modalObject={modalObject} />}
        </Fragment>
      )
    );
  });
};

export default TextFieldComponent;
