import { Fragment } from "react";
import { Link } from "react-router-dom";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertComponent } from "../../components";
import { EventUtils } from "../../utils";
import { isMobileDevice } from "../../constants";

export const ConfirmedItemComponent = (props: any) => {
  const { finishEnrollmentData } = props;

  return (
    <>
      {finishEnrollmentData?.map((item: any, index: number) => {
        return (
          <Fragment key={index}>
            {item?.isexists && (
              <div>
                <AlertComponent marginleft={false} propmessageType={item?.messagetype === "DANGER" ? "danger clearfix" : " clearfix pb-3 pb-md-4"} propmessage={item?.message} />
                <div className="mb-3 item" style={{ backgroundColor: item?.backcolor }}>
                  <div className={`d-flex justify-content-between  confirmationitem "p-3"`}>
                    {item?.title && <p className="m-0">{item?.title}</p>}
                    {item?.confirmationstatment ? (
                      <div className="d-flex justify-content-between align-items-center clickbtn">
                        <FontAwesomeIcon icon={faFileDownload} />
                        {isMobileDevice ? (
                          <Link to={`/ReviewCoverage/CurrentCoverage?${item?.parameters}`} className="m-1 ps-2">
                            {item?.buttontitle}
                          </Link>
                        ) : (
                          <Link target="blank" to={`/ReviewCoverage/CurrentCoverage?${item?.parameters}`} aria-label={item?.buttontitle + " " + item?.lblnewtabtext} className="m-1 ps-2">
                            {item?.buttontitle}
                          </Link>
                        )}
                      </div>
                    ) : (
                      <div
                        className="d-flex justify-content-between align-items-center clickbtn"
                        role="button"
                        tabIndex={0}
                        onClick={item?.navigateclicked}
                        onKeyDown={(e) => EventUtils.keyPressed(e) && item.navigateclicked(e)}
                      >
                        <FontAwesomeIcon icon={faFileDownload} />
                        <p className="m-1 ps-2">{item?.buttontitle}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default ConfirmedItemComponent;
