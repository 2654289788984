import { Form } from "react-bootstrap";
import { TooltipIconComponent } from "../Icon";
export const ChoiceListComponent = (props: any) => {
  const { choicelistdata } = props;
  const { type, tooltip, legendtext, fgclass, className, name, disabled, isInvalid, selected, width, ischecked, isCallbackParam, dependentID, handlechange, component, wrapperclass } = choicelistdata;
  const rejectItemList: any = [undefined, "undefined", "Undefined", "indéfinie", "Indéfinie", "Non défini"];

  const onHandleChange = (event: any) => {
    if (handlechange && !isCallbackParam) {
      event.persist();
      const dataName = event.target.getAttribute("data-name");

      const { name, value, checked }: any = event.target;
      const val = ischecked ? checked : value;
      const changedValues: any = { [ischecked ? dataName : name]: val };
      dependentID ? (changedValues.dependentID = dependentID) : null;
      return handlechange(changedValues);
    } else {
      event.persist();
      return handlechange(event);
    }
  };
  const arroptions: any = [];
  choicelistdata?.optionsinput?.map((opt: any, i: number) => {
    if (!rejectItemList.includes(opt?.text)) {
      const newName = opt?.name ? opt?.name : name;
      const uniqID = opt?.id ? opt?.id : `${newName}-${i}`;
      const isChecked: boolean = !ischecked
        ? selected === opt?.value || parseInt(selected) === parseInt(opt?.value) || (typeof selected === "boolean" && typeof opt?.value !== "boolean" && selected)
        : selected;
      arroptions.push(
        <div key={uniqID} className={`${width ? "w-" + width : ""} ${fgclass || ""}`}>
          <Form.Check inline type={type} id={uniqID} className={className}>
            <Form.Check.Input
              type={type === "switch" || type === "checkbox" ? "checkbox" : "radio"}
              disabled={disabled}
              aria-disabled={disabled}
              isInvalid={isInvalid}
              value={opt?.value}
              aria-checked={isChecked}
              checked={isChecked}
              className={className}
              data-name={newName}
              onChange={onHandleChange}
              onSelect={onHandleChange}
              name={name}
            />
            {type !== "switch" ? (
              <Form.Check.Label
                dangerouslySetInnerHTML={{
                  __html: opt?.text
                }}
              />
            ) : (
              ""
            )}
          </Form.Check>

          {choicelistdata?.tooltipicon && <TooltipIconComponent inputData={choicelistdata?.tooltipicon} />}
        </div>
      );
    }
  });

  return (
    <>
      {/* {choicelistdata?.optionsinput?.length > 1 ? ( */}
      <div className="form-holder clearfix">
        <Form.Group controlId={`${component}-${name}`} className={`clearfix mb-0 ${wrapperclass ? wrapperclass : ""}`}>
          {tooltip ? (
            <legend className="mb-3">
              {legendtext}
              <TooltipIconComponent inputData={tooltip} />
            </legend>
          ) : (
            legendtext && <legend dangerouslySetInnerHTML={{ __html: legendtext }}></legend>
          )}
          {arroptions}
        </Form.Group>
        {choicelistdata?.error && <div className={choicelistdata?.errorclass}>{choicelistdata?.error}</div>}
      </div>
      {/* ) : (
        arroptions
      )} */}
      {/* {choicelistdata?.error && choicelistdata?.optionsinput?.length === 1 && <div className={choicelistdata?.errorclass}>{choicelistdata?.error}</div>} */}
    </>
  );
};

export default ChoiceListComponent;
