import Card from "react-bootstrap/Card";
import { DescriptionComponent, RadioWithEmailComponent } from "../Form";
import { ButtonComponent } from "../Button";
import { GridComponent } from "../Card";
import { Form } from "react-bootstrap";
import { DeleteIconComponent, EditIconComponent } from "../Icon";

export const CardComponent = ({ children, ...props }: any) => {
  const { cardClass, head, body, footer, errorList, cardHeaderComponent } = props?.gridObject || {};
  return (
    <Card className={`w-100 ${cardClass ?? ""}`}>
      <Card.Header>
        <DescriptionComponent level="2" headingclassname={head?.headerclass} heading={head?.gridtitle} />
        {head?.showediticon && <EditIconComponent inputData={head?.editbtndata} />}
        {head?.showdeleteicon && <DeleteIconComponent inputData={head?.deletebtndata} />}
      </Card.Header>
      {(body || children) && (
        <Card.Body className="personal-info-holder titleandicon">
          {/* <div className={`row`}> */}
          {errorList?.length ? (
            <div className="row mb-1">
              <div className="col-12">
                {errorList?.map((e: any, i: number) => (
                  <p key={i} className="ml-inputfields has-error formErrorPStyle" role="alert">
                    {e}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            ""
          )}
          {cardHeaderComponent ? cardHeaderComponent : ""}

          {body?.heading && <h3 dangerouslySetInnerHTML={{ __html: body?.heading }} />}
          {body?.description && <p className={`card-text mb-4`} dangerouslySetInnerHTML={{ __html: body?.description }} />}

          {!body?.viewbodyMode &&
            body?.gridRowData?.map((item: any, index: number) => {
              return item && <GridComponent key={index} bodyData={item} rowdata={item[0]} />;
            })}

          {body?.messageText && (
            <div className={`ml-description mb-3 clearfix m-2`}>
              <p className={`card-text m-0`} dangerouslySetInnerHTML={{ __html: body?.messageText }} />
            </div>
          )}
          {/* </div> */}
          {body?.viewbodyMode === "email" ? (
            <div className="form-holder clearfix">
              <Form.Group as="fieldset" className="clearfix mb-0 w-100">
                {body?.bodytitle && <legend className="mb-3" dangerouslySetInnerHTML={{ __html: body?.bodytitle }}></legend>}
                {body?.gridRowData?.map((item: any, index: number) => {
                  return <RadioWithEmailComponent key={index} bodyData={item} legendtext={index == 0 ? body?.bodytitle : ""} />;
                })}
              </Form.Group>
            </div>
          ) : (
            ""
          )}
          {children}
        </Card.Body>
      )}
      {footer?.showFooter && footer?.footerData && (
        <Card.Footer>
          <ButtonComponent btndata={footer?.footerData} />
        </Card.Footer>
      )}
    </Card>
  );
};

export default CardComponent;
