import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getShowFlex, setMiniCartInitialState } from "../../actions";
import { ButtonComponent, TableComponent } from "../../components";
import { RootState } from "../../reducers";
import { ObjectUtils } from "../../utils";
import FocusLock from "react-focus-lock";

export const EnrollmentHeader = () => {
  const dispatch = useDispatch();
  const { showFlexData } = useSelector((state: RootState) => state?.benefit);
  const [showFlexBreakDownListModal, setshowFlexBreakDownListModal] = useState<boolean>(false);
  const [modalType, setmodalType] = useState<string>("");

  const getFlexData = useCallback(async () => {
    dispatch(await getShowFlex());
  }, [dispatch]);

  useEffect(() => {
    //This gets app config data and save into redux store
    !showFlexData && getFlexData();
  }, [getFlexData, showFlexData]);

  const awardedFlexLabel = showFlexData?.headerTotalFlexAwarded;
  const remainingFlexLabel = showFlexData?.headerRemainingFlex;
  const deductionsLabel = showFlexData?.headerPerPayDeductions;
  const showFlex = showFlexData?.showFlex;
  const awardedFlexVisble = showFlexData?.awardedFlexVisible;
  const remainingFlexVisible = showFlexData?.remainingFlexVisible;
  const deductionsVisible = showFlexData?.deductionsVisible;
  const totalFlexAwarded = showFlexData?.totalFlexAwarded;
  const remainingFlex = showFlexData?.remainingFlex;
  const deductionAmout = showFlexData?.deductions;
  const flexZeroValues = ["$0.00", "$0.0", "$0"];
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents || {};
  const closeButtonText = ObjectUtils.extractLabelFromObject(footerContent, "btnClose");
  const flexDetail = showFlexData?.flexDetail ? showFlexData?.flexDetail : {};
  const breakDownList = flexDetail?.breakDownList ? flexDetail?.breakDownList : [];
  const deductionsDetail = showFlexData?.deductionsDetail ? showFlexData?.deductionsDetail : {};
  const breakDownListDeduction = deductionsDetail?.breakDownList ? deductionsDetail?.breakDownList : [];
  const lblPerPayDeductionsTaxNote = deductionsDetail.taxNote || "";

  const resultbreakDownList = showFlexBreakDownListModal && modalType === "deduction" ? breakDownListDeduction : breakDownList;

  /* const handleModalType = (modalType: any) => {
    setshowFlexBreakDownListModal(true);
    setmodalType(modalType);
  }; */

  const handleShow = (modalType: string) => {
    setMiniCartInitialState(true);
    setshowFlexBreakDownListModal(true);
    setmodalType(modalType);
  };

  const handleClose = () => {
    setMiniCartInitialState(false);
    setshowFlexBreakDownListModal(false);
    setmodalType("");
  };

  const gridRowBodyObject: any = [];
  resultbreakDownList?.map((item: any) => {
    const gridRowData: any = [
      {
        type: "tcell",
        display: item?.description
      },
      {
        type: "tcell",
        display: item?.value,
        colclassname: "text-end"
      }
    ];
    gridRowBodyObject.push(gridRowData);
  });

  const gridRowData: any = [
    {
      type: "tcell",
      display: ""
    },
    {
      type: "tscell",
      display: `${lblPerPayDeductionsTaxNote}`
    }
  ];

  gridRowBodyObject.push(gridRowData);

  const breakDownTableObject = {
    className: "ml-popuptable w-100",
    body: {
      gridRowData: gridRowBodyObject
    }
  };

  const dedubuttonInput = [
    {
      display: deductionAmout,
      variant: "link",
      displayclass: "ml-enrollmentvalue",
      icon: !modalType || modalType === "awarded" ? faChevronDown : " ",
      rightpos: true,
      ariaLabel: deductionsLabel + " " + deductionAmout,
      onSubmit: () => handleShow("deduction")
    }
  ];

  const awardedbuttonInput = [
    {
      display: totalFlexAwarded,
      variant: "link",
      displayclass: "ml-enrollmentvalue",
      icon: !modalType || modalType === "deduction" ? faChevronDown : " ",
      rightpos: true,
      ariaLabel: awardedFlexLabel + " " + totalFlexAwarded,
      onSubmit: () => handleShow("awarded")
    }
  ];

  const closebuttonInput = [
    {
      variant: "link ml-btnpopupclose close",
      texttoread: closeButtonText.textToRead,
      ariaLabel: closeButtonText.textToRead,
      icon: faTimes,
      onSubmit: () => handleClose()
    }
  ];

  return (
    <>
      {showFlex && (
        <div className="ml-healthheader d-flex justify-content-end enrollment-header mb-md-4">
          {awardedFlexVisble && (
            <div className={`ml-col col-4 col-md-4 ml-colawarded ${showFlexBreakDownListModal && modalType === "awarded" ? "active bgsecondary" : ""}`}>
              <span className="ml-enrollmenttitle">{awardedFlexLabel}</span>
              {flexZeroValues.indexOf(totalFlexAwarded) === -1 ? (
                <ButtonComponent btndata={awardedbuttonInput} />
              ) : (
                <div className="ml-enrollmentvalue">
                  <span className="visuallyhidden">{awardedFlexLabel}</span>
                  {totalFlexAwarded}
                </div>
              )}
            </div>
          )}

          {remainingFlexVisible && (
            <div className="ml-col col-4 col-md-4">
              <span className="ml-enrollmenttitle">{remainingFlexLabel}</span>
              <div className="ml-enrollmentvalue">
                <span className="visuallyhidden">{remainingFlexLabel}</span>
                {remainingFlex}
              </div>
            </div>
          )}

          {deductionsVisible && (
            <div className={`ml-col col-4 col-md-4 ml-coldeduction ${showFlexBreakDownListModal && modalType === "deduction" ? "active bgprimary" : ""}`}>
              <span className="ml-enrollmenttitle">{deductionsLabel}</span>
              {flexZeroValues.indexOf(deductionAmout) === -1 ? (
                <ButtonComponent btndata={dedubuttonInput} />
              ) : (
                <div className="ml-enrollmentvalue">
                  <span className="visuallyhidden">{deductionsLabel}</span>
                  {deductionAmout}
                </div>
              )}
            </div>
          )}
          {showFlexBreakDownListModal && (
            <FocusLock autoFocus={true} noFocusGuards={true}>
              <div className={`ml-dropdowncontent ${modalType === "awarded" ? "alignleft bgsecondary open" : ""} ${modalType === "deduction" ? "alignright bgprimary open" : ""}`}>
                <div className="scroll-container">
                  <div className="ml-healthpopup">
                    {breakDownTableObject ? <TableComponent tableObject={breakDownTableObject}></TableComponent> : null}
                    <ButtonComponent btndata={closebuttonInput} />
                  </div>
                </div>
              </div>
            </FocusLock>
          )}
        </div>
      )}
    </>
  );
};

export default EnrollmentHeader;
