import React, { Component } from "react";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
    document.title = "Application Error";
    document.body.style.setProperty("background", "#f8d7d9");
  }

  render() {
    if (this.state.hasError) {
      return (
        <main className="error-center">
          <h1 className="mb-4">Error:</h1>
          <div className="mt-4">The system was not able to complete the last operation you tried to perform. Please contact your benefit plan administrator.</div>
          <div className="mt-4">La dernière transaction effectuée n’a pu être complétée. Veuillez contacter votre administrateur de régime.</div>
        </main>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
