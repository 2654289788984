import { Fragment } from "react";
import { TextFieldComponent, ChoiceListComponent } from "../Form";
export const RadioWithEmailComponent = (props: any) => {
  const { bodyData } = props || [];

  return (
    bodyData && (
      <div className={`row mb-1 ${bodyData?.rowclassname || ""}`}>
        {bodyData?.map((item: any, index: number) => {
          return (
            <Fragment key={`${index}`}>
              {!item?.skip && (
                <div className={item?.colclassname}>
                  {item?.type === "choicelist" && <ChoiceListComponent choicelistdata={item?.options} />}
                  {item?.type === "text" && <TextFieldComponent inputdata={[item]} />}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    )
  );
};
export default RadioWithEmailComponent;
