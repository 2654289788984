import { useSelector } from "react-redux";
import { RootState } from "../../reducers";

export const Spinner = () => {
  const { isLoading } = useSelector((state: RootState) => state.status);
  return isLoading ? (
    <div className="loaderwrapper">
      <div className="loader">
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot primarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
        <div className="dot secondarycolor"></div>
      </div>
    </div>
  ) : null;
};

export default Spinner;
