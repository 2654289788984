import { Link } from "react-router-dom";
import { ObjectUtils } from "../../utils";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers";
import { DescriptionComponent } from "../../components/Form";
import { isMobileDevice } from "../../constants";

export const CoverageListComponent = (props: any) => {
  const contentFooter = useSelector((state: RootState) => state.common.contentFooter);
  const footerContent = contentFooter?.contentResource?.contents;
  const lblNewTab = ObjectUtils.extractLabelFromObject(footerContent || {}, "lblNewTab");
  const { reviewPastCoverage, btnDownload, pageTitle } = props;
  return (
    <>
      <DescriptionComponent level="2" heading={pageTitle} />
      {reviewPastCoverage?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            className={`row justify-content-between align-items-center border-top py-2 py-md-3 m-0 coverageitem`}
          >
            <div className={`col-8 col-sm-auto p-0 pr-sm-3`} dangerouslySetInnerHTML={{ __html: item.display }} />
            <div className={`col-4 col-sm-auto p-0 ps-sm-3`}>
              <Link
                role="button"
                to={{
                  pathname: "/coverage",
                  search: "?dateString=" + item.dateString
                }}
                target={isMobileDevice ? "" : "___blank"}
                aria-label={!isMobileDevice ? btnDownload + " " + lblNewTab.textToRead : ""}
                className={`btn btn-secondary`}
              >
                {btnDownload}
              </Link>
            </div>
          </div>
        );
      })}
      <div className={`mt-3`}></div>
    </>
  );
};

export default CoverageListComponent;
