import { EventUtils } from "../../utils";
import { DeletePersonIcon } from "../../assets/icons";

export const DeleteIconComponent = (props: any) => {
  const { ariaLabel, className, onSubmit, iconbtn }: any = props?.inputData;
  return (
    <span tabIndex={0} role="button" aria-label={ariaLabel} className={className} onClick={onSubmit} onKeyDown={(e) => EventUtils.keyPressed(e) && onSubmit(e)}>
      {iconbtn ? iconbtn : <DeletePersonIcon />}
    </span>
  );
};
export default DeleteIconComponent;
