export const DescriptionComponent = (props: any) => {
  const { description, description2, heading, headingclassname, level, classname, descriptionclass, hideextra } = props;
  const Heading: any = "h" + level;
  const isDesc = description || description2;
  return (
    <>
      {heading && <Heading className={headingclassname}>{heading}</Heading>}

      {!hideextra && (
        <div className={`${classname || ""} login-form description`}>
          {isDesc && (
            <>
              <div className={`${descriptionclass || ""}`} dangerouslySetInnerHTML={{ __html: description }} />
              {description2 && <div className="pt-3" dangerouslySetInnerHTML={{ __html: description2 }} />}
            </>
          )}
        </div>
      )}
    </>
  );
};
export default DescriptionComponent;
