import { AlertComponent } from "../Alert";
import { CalenderComponent } from "../Calender";
import { ChoiceListComponent, DropdownComponent, FileComponent, TextFieldComponent } from "../Form";

export const GridComponent = (props: any) => {
  const { bodyData, rowdata, rowclassname } = props || {};

  return (
    <>
      <div className={`row ${rowdata?.rowclassname || ""}${rowclassname || ""}`}>
        {bodyData?.map((item: any, index: number) => {
          return (
            !item?.skip &&
            item?.colclassname && (
              <div key={`${index}`} className={item?.colclassname}>
                {item?.type === "text" && <TextFieldComponent inputdata={[item]} />}
                {item?.type === "select" && <DropdownComponent inputdata={[item]} />}
                {item?.type === "choicelist" && <ChoiceListComponent choicelistdata={item?.options} />}
                {item?.type === "file" && <FileComponent inputdata={[item]} />}
                {item?.type === "calendar" && <CalenderComponent {...item} />}
                {item?.type === "instructions" && <AlertComponent propmessageType={`${item?.classname ?? "secondary"}`} propmessage={item?.display} />}
              </div>
            )
          );
        })}
        {!rowdata?.skip && rowdata?.message && <AlertComponent marginleft={false} propmessageType={rowdata?.messagetype} propmessage={rowdata?.message} />}
      </div>
    </>
  );
};

export default GridComponent;
