import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventUtils } from "../../utils";

export const EditIconComponent = (props: any) => {
  const { ariaLabel, className, onSubmit }: any = props?.inputData;
  return (
    <span tabIndex={0} role="button" aria-label={ariaLabel} className={className} onClick={onSubmit} onKeyDown={(e) => EventUtils.keyPressed(e) && onSubmit(e)}>
      <FontAwesomeIcon icon={faPencilAlt} />
    </span>
  );
};
export default EditIconComponent;
