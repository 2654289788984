import { Form } from "react-bootstrap";
import {
  ButtonComponent,
  ChoiceListComponent,
  DescriptionComponent,
  GridComponent,
  TextFieldComponent
} from "..";
import OrderedList from "./OrderedListComponent";
export const FormComponent = (props: any) => {
  const {
    formdata: { texttoread, validated, classname, handlesubmit },
    inputdata,
    btndata,
    descData,
    gridRowData,
    orderedList,
    rowclassname,
    btnviewmode,
    btnviewclass,
    optionData
  } = props;
  
  return (
    <Form className={classname} role="form" aria-label={texttoread} noValidate validated={validated} onSubmit={handlesubmit}>
      <OrderedList listData={orderedList}></OrderedList>
      {inputdata && <TextFieldComponent inputdata={inputdata} />}
      {descData && <DescriptionComponent descriptionclass={descData?.[0].classname} description={descData?.[0].passwordGuideLineMsg} />}
      {optionData && <ChoiceListComponent choicelistdata={optionData} />}
      {gridRowData?.map((item: any, index: number) => {
        return item && <GridComponent key={index} bodyData={item} rowclassname={rowclassname} />;
      })}
      {!btnviewmode && btndata && (
        <>
          <ButtonComponent btndata={btndata} />
          <br />
          <br />
        </>
      )}
      {btnviewmode && btndata && (
        <div className={btnviewclass || "d-flex justify-content-between ml-popupbuttons border-top pt-3"}>
          <ButtonComponent btndata={btndata} />
        </div>
      )}
    </Form>
  );
};

export default FormComponent;
