import { ButtonComponent } from "../../components";
import { isMobileDevice } from "../../constants";
export const CoverageComponent = (props: any) => {
  const { buttonInput, buttonBack, coverageData } = props;
  return (
    <div className={`coveragewrapper`}>
      <div className={`coveragebox clearfix`}>
        <div className="pt-4 pb-4 mb-4 btnbox d-flex justify-content-between align-items-center">
          <div className={`d-flex titlewithicon`}>{isMobileDevice ? <>{coverageData && <ButtonComponent btndata={buttonBack} />} </> : ""}</div>
          {coverageData && <ButtonComponent btndata={buttonInput} />}
        </div>
        <div dangerouslySetInnerHTML={{ __html: coverageData?.html }} className={`reviewcoverage clearfix`}></div>
        <div className={`clearfix`}></div>
        {coverageData && <ButtonComponent btndata={buttonInput} />}
      </div>
      <div className={`mt-4`}></div>
    </div>
  );
};

export default CoverageComponent;
