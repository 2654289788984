import { useState, useEffect } from "react";
import { ConfirmedItemComponent } from "../../components/Coverages";
import { downloadFinishedEnrollmentFile } from "../../actions";

export const EnrollmentBenefitComponent = (props: any) => {
  const { benefittitle, coverlettertitle, downloadbuttonlabel, nocarrier, nocarriermessage, coverletterfile, questionairetitle, questionairefile } = props;
  const eobFormButtonColor = "#f7f7f8";

  const [noCarrier, setNoCarrier] = useState(false);

  useEffect(() => {
    setNoCarrier(nocarrier);
  }, [nocarrier]);

  const coverLetterButtonClicked = () => {
    downloadFinishedEnrollmentFile(coverletterfile, "CoverLetter");
  };

  const questionaireButtonClicked = () => {
    window.open(questionairefile);
  };

  const inputData = [
    {
      isexists: coverletterfile ? true : false,
      title: coverlettertitle,
      buttontitle: downloadbuttonlabel,
      navigateclicked: coverLetterButtonClicked,
      backcolor: eobFormButtonColor
    },
    {
      isexists: questionairefile ? true : false,
      title: questionairetitle,
      buttontitle: downloadbuttonlabel,
      navigateclicked: questionaireButtonClicked,
      backcolor: eobFormButtonColor
    }
  ];

  return (
    <div className="clearfix itemsection">
      <p className="mb-3">{benefittitle}</p>
      {inputData && <ConfirmedItemComponent finishEnrollmentData={inputData} />}
      {noCarrier && <p className="m-0">{nocarriermessage}</p>}
    </div>
  );
};

export default EnrollmentBenefitComponent;
