import { ButtonComponent } from "../Button";
import { ChoiceListComponent, DropdownComponent, TextFieldComponent } from "../Form";

export const TableRowComponent = ({ gridRowData,benefitName }: any) => {
  return (
    gridRowData &&
    gridRowData?.map((data: any, index: number) =>
      data && !data?.skip && !data?.viewSupplement ? (
        <td
          key={data?.datatitle + "-" + data?.type+'-'+ benefitName + "-" + index}
          data-row={data?.datatitle + "-" + data?.type +'-'+ benefitName  + "-" + index}
          className={data?.colclassname || ""}
          data-title={data?.datatitle}
          colSpan={data?.colSpan}
        >
          {data?.type === "tcell" && data?.display}
          {data?.type === "tscell" && (
            <strong
              dangerouslySetInnerHTML={{
                __html: data?.display
              }}
            />
          )}
          {data?.type === "text" && <TextFieldComponent inputdata={[data]} />}
          {data?.type === "number" && <TextFieldComponent inputdata={[data]} />}
          {data?.type === "select" && <DropdownComponent inputdata={[data]} />}
          {data?.type === "choicelist" && <ChoiceListComponent choicelistdata={data?.options} />}
          {data?.type === "submit" && <ButtonComponent btndata={[data]}></ButtonComponent>}
          {data?.type === "alert" && data?.errormessage && data?.errormessage}
        </td>
      ) : (
        <>
          {data && (
            <td colSpan={gridRowData?.length} key={index} className={data?.colclassname}>
              <TextFieldComponent istabbed={true} inputdata={[data]} />
            </td>
          )}
        </>
      )
    )
  );
};

export default TableRowComponent;
