import { EventUtils } from "../../utils";
import { ToolTipIcon } from "../../assets/icons";

export const TooltipIconComponent = (props: any) => {
  const { ariaLabel, className, onSubmit }: any = props?.inputData;

  //props?.tooltipdata;
  return (
    <span tabIndex={0} role="button" aria-label={ariaLabel} className={className} onClick={onSubmit} onKeyDown={(e) => EventUtils.keyPressed(e) && onSubmit(e)}>
      <ToolTipIcon />
    </span>
  );
};
export default TooltipIconComponent;
