import React from "react";

function OrderedList(listData: any) {
  return (
    <ol>
      {listData?.listData?.map((item?, index?) =>
        item[0].keyName === "qrCodeLink" ? (
          <li className="nobull" key={index}>
            <img src={item[0]?.display} alt={item[0]?.keyName} />
          </li>
        ) : (
          <li
            key={index}
            dangerouslySetInnerHTML={{
              __html: item[0].display,
            }}
          />
        )
      )}
    </ol>
  );
}

export default OrderedList;
