import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { TextFieldComponent } from "../Form";
import { isMobileDevice } from "../../constants";
import { USER_TOKEN } from "../../utils";

export const CalenderComponent = (props: any) => {
  const textInputRefs = useRef<HTMLInputElement[]>([]);
  const { isreadonly, islifeevent, datevalid, mode, name, display, placeholder, min_date, max_date, errormessage, wrapperclass, hidelifeeventeffectivedate, width, checkdob } = props;
  const [, setisReadOnly] = useState<boolean>(JSON.parse(isreadonly));
  const editMode = mode === "edit" ? true : false;
  const seperator = placeholder?.substring(2, 3);
  const dataformat = placeholder?.split(seperator);
  const localestring = USER_TOKEN?.get()?.lang?.substring(0, 2);

  const setDateZero = (date: number) => {
    return date < 10 ? "0" + date?.toString() : date?.toString();
  };

  const caldate = {
    month: props.date?.month ? setDateZero(props.date.month) : "",
    day: props.date?.day ? setDateZero(props.date.day) : "",
    year: props.date?.year ? props.date.year : ""
  };

  const [calander, setCalander] = useState(caldate);
  const [, setIsEmpty] = useState<boolean>(false);
  const [isFocused] = useState<boolean>(props.date ? true : false);

  useEffect(() => {
    if (islifeevent === "true") {
      const eventDate = props?.max_date;
      const caldate = {
        month: setDateZero(eventDate?.getMonth() + 1),
        day: setDateZero(eventDate?.getDate()),
        year: eventDate?.getFullYear()?.toString()
      };
      const updateIsEmpty = Object.values(caldate).every((x) => x === null || x === "");

      if (!updateIsEmpty && hidelifeeventeffectivedate === "false") {
        setCalander(caldate);
        setIsEmpty(updateIsEmpty);
      } else {
        setCalander({
          month: "",
          day: "",
          year: ""
        });
      }
    }
  }, [hidelifeeventeffectivedate, islifeevent, props?.max_date]);

  const outSideClickHandler = useCallback(
    (calender_date: any) => {
      const { day, month, year } = calender_date;
      const now = new Date();
      let eventDate = new Date(year + "/" + month + "/" + day + " " + now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds());

      if (islifeevent) {
        if (eventDate <= min_date) {
          eventDate = min_date;
        } else if (eventDate >= max_date) {
          eventDate = max_date;
        }
      } else {
        const currentDate = new Date();
        eventDate = eventDate > currentDate ? currentDate : eventDate;
      }

      const caldate = {
        month: setDateZero(eventDate.getMonth() + 1),
        day: setDateZero(eventDate.getDate()),
        year: eventDate.getFullYear().toString()
      };

      const calYear = caldate.year === "NaN" || caldate.year === "";
      const calDay = caldate.day === "NaN" || caldate.day === "";
      const calMonth = caldate.month === "NaN" || caldate.month === "";
      if (calDay || calYear || calMonth) {
        setCalander({
          month: "",
          day: "",
          year: ""
        });
        setIsEmpty(true);
      } else {
        setCalander(caldate);

        if (islifeevent === "true") {
          props.onChange(eventDate);
        } else {
          props.onChange(caldate);
        }
      }
    },
    [islifeevent, max_date, min_date, props]
  );

  const keyPressHandler = (e: any, index: number) => {
    const {
      keyCode,
      target: { name, value }
    } = e;

    const isBackSpace = keyCode === 8;
    const length = value?.length;
    const isYear = name === "year";
    const isNotYear = name !== "year";
    const isNumaric = (keyCode > 47 && keyCode < 58) || (keyCode > 96 && keyCode < 105);

    if (isBackSpace && length === 0 && isYear) {
      textInputRefs.current[1]?.focus();
    } else if (isBackSpace && length === 0 && isNotYear) {
      textInputRefs.current[0]?.focus();
    }
    if (isNumaric && length > 3 && isYear) {
      textInputRefs?.current[2]?.select();
    } else if (isNumaric && length > 1 && isNotYear) {
      textInputRefs.current[index].select();
    }
    if (e.keyCode === 9) {
      outSideClickHandler(calander);
    }
  };

  const handleClickOutside = useCallback(() => {
    outSideClickHandler(calander);

    document.removeEventListener("click", handleClickOutside);
  }, [calander, outSideClickHandler]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [calander, handleClickOutside]);

  const setDOBValue = (callback: any) => {
    setIsEmpty(calander?.year === "" ? true : false);
    const name = Object.keys(callback)?.[0];
    const value: any = Object.values(callback)?.[0];
    const caldate = { ...calander };

    if (caldate.year === "") {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }

    if (!isNaN(Number(value))) {
      caldate[name] = value;
      setCalander(caldate);
      props.onChange(caldate);
    } else {
      setCalander({
        month: "",
        day: "",
        year: ""
      });
    }
    // });
    const isFrench = localestring === "fr";
    const mIndex = !isFrench ? 1 : 2;
    const dIndex = !isFrench ? 2 : 1;
    if (name === "month" && value?.length === 2) {
      textInputRefs.current[mIndex]?.focus();
    } else if (name === "day" && value?.length === 2) {
      textInputRefs.current[dIndex]?.focus();
    } else if (name === "year" && value.length === 4) {
      setCalander(caldate);
      outSideClickHandler(caldate);
    }
  };

  const labelC =
    name === "dateOfBirth" ? (
      <Form.Label {...props}>
        <span dangerouslySetInnerHTML={{ __html: display }} />
        &nbsp;
      </Form.Label>
    ) : (
      <Form.Label {...props}>{props.display}&nbsp;</Form.Label>
    );
  let dateco: any = "";

  const handleCalender = () => {
    const calDay = calander.day === "";
    const calMonth = parseInt(calander.month) > 12;
    console.log("calMonth> ", calMonth);
    // const calYear = calander.year === "";
    if ((calDay && localestring === "fr") || calMonth) {
      const calndate = {
        month: "",
        day: "",
        year: ""
      };
      setIsEmpty(true);
      setCalander(calndate);
      props.onChange(calndate);
    }
  };

  if (editMode) {
    let date;
    if (islifeevent === "true") {
      date = props.date ? props.date : new Date();
      if (date.year) {
        const { year, month, day } = date;
        date = new Date(year, month, day);
      } else {
        date = new Date(Date.parse(date));
      }
    } else {
      date = props.date ? props.date : null;
      if (date !== null) {
        const { year, month, day } = date;
        date = new Date(year, month, day);
      }
    }
    if (props.format) {
      /* const textInput = [
        {
          type: "text",
          name: "day",
          texttoread: dataformat?.[0],
          className: `ddfield ${isFocused ? "show" : "hide"}`,
          maxLength: 2,
          refInput: (ref: HTMLInputElement) => (textInputRefs.current[0] = ref)
        }
      ];
      dateco = (
        <div className={`w-50 custInput dob-blk-main ${props.checkdob === "true" && props.formvalidated === "true" ? "invalid" : ""}`}>
          <div id="dob-div" className="d-flex align-items-center dob-blk dob-blk-none">
            <TextFieldComponent inputdata={textInput} />
          </div>
        </div>
      ); */
    } else {
      const textInput = [
        {
          autoComplete: "off",
          type: "text",
          mode: "edit",
          name: `${dataformat?.[0] === "MM" ? "month" : "day"}`,
          texttoread: dataformat?.[0],
          classname: `${isFocused ? "show" : "hide"}`,
          maxLength: 2,
          fgclass: `${isMobileDevice ? "mb0" : ""}`,
          refInput: (ref: HTMLInputElement) => (textInputRefs.current[0] = ref),
          value: localestring === "fr" ? calander?.day : calander?.month,
          handlechange: setDOBValue,
          handlekeydown: keyPressHandler,
          seperator,
          isFocused,
          isCalander: true
        },
        {
          autoComplete: "off",
          type: "text",
          mode: "edit",
          name: `${dataformat?.[1].toUpperCase() === "MM" ? "month" : "day"}`,
          texttoread: dataformat?.[1],
          classname: `${isFocused ? "show" : "hide"}`,
          maxLength: 2,
          fgclass: `${isMobileDevice ? "mb0" : ""}`,
          refInput: (ref: HTMLInputElement) => (textInputRefs.current[1] = ref),
          value: localestring === "fr" ? calander?.month : calander?.day,
          handlechange: setDOBValue,
          handlekeydown: keyPressHandler,
          seperator,
          isFocused,
          isCalander: true
        },
        {
          autoComplete: "off",
          type: "text",
          name: "year",
          mode: "edit",
          texttoread: dataformat?.[2],
          classname: `yyfield ${isFocused ? "show" : "hide"}`,
          maxLength: 4,
          fgclass: `${isMobileDevice ? "mb0" : ""}`,
          refInput: (ref: HTMLInputElement) => (textInputRefs.current[2] = ref),
          value: calander?.year,
          handlechange: setDOBValue,
          handlekeydown: keyPressHandler,
          isCalander: true
        }
      ];

      dateco = (
        <>
          <div className={`custInput dob-blk-main ${width ?? ""}${islifeevent === "true" && datevalid === "false" ? "invalid" : ""} ${checkdob === "true" ? "invalid" : ""}`} onBlur={handleCalender}>
            <div id="dob-div" className="d-flex align-items-center dob-blk dob-blk-none">
              <TextFieldComponent inputdata={textInput} />
            </div>
          </div>
          <div>
            {errormessage ? (
              <div className="invalid-feedback d-block" role="alert">
                {errormessage}
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      );
    }
  }
  return (
    <div className={`clearfix ${wrapperclass}`}>
      {labelC}
      {isMobileDevice ? <div className="react-datepicker-wrapper">{dateco}</div> : dateco}
      {!editMode && <FontAwesomeIcon onClick={() => setisReadOnly(false)} className="icon-action" icon={faCalendarAlt} />}
    </div>
  );
};

export default CalenderComponent;
